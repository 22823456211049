
import { Component } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class CustomerForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'email', type: InputType.Text, label: 'customer.form.email', rules: 'required|email' },
      { name: 'gender', type: InputType.Select, label: 'customer.form.gender', rules: 'required', items: this.gender },
      { name: 'name', type: InputType.Text, label: 'customer.form.name', rules: 'required' },
      { name: 'city', type: InputType.Text, label: 'customer.form.city' },
      { name: 'postalCode', type: InputType.Text, label: 'customer.form.postalCode' },
      { name: 'street', type: InputType.Text, label: 'customer.form.street' },
      { name: 'number', type: InputType.Text, label: 'customer.form.number' },
    ];
  }

  get gender() {
    return [
      { text: this.$t('gender.male'), value: 'male' },
      { text: this.$t('gender.female'), value: 'female' },
      { text: this.$t('gender.other'), value: 'other' },
    ];
  }
}
