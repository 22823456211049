
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import EditCustomerLoader from '@/components/customer/EditCustomerLoader.vue';
import CustomerForm from '@/components/customer/CustomerForm.vue';
import { Customer } from '@/interfaces/models/Customer';
import { Venue } from '@/interfaces/models/Venue';
import StackedForm from '@/mixins/StackedForm';

const customer = namespace('customer');
const venue = namespace('venue');

@Component({
  components: { CustomerForm, EditCustomerLoader, VWrapper },
})
export default class EditCustomer extends mixins(Editable, Notification, StackedForm) {
  @venue.State('active') public venue!: Venue;

  @customer.Action('store') public store!: any;
  @customer.Action('update') public update!: any;
  @customer.Action('show') public show!: any;
  @customer.State('active') public customer!: Customer;

  public $refs!: {
    form: InstanceType<typeof CustomerForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get title() {
    return this.editing ? 'customer.edit' : 'customer.create';
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('customer');
      await this.show({ id: this.id });
    }
    if (this.editing && !this.customer) {
      this.$router.push({ name: 'customer.index' });
    }
    this.$stopLoading('customer');
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (this.isValid(res)) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.customer!._id });
        } else {
          await this.store({ ...this.$refs.form.getData(), venue: this.venue._id });
        }
        this.$router.push({ name: 'customer.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
